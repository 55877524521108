import { service as request } from '@/libs/request'
import requestno from '@/libs/requestNoAuth'
import { getToken, setSession } from "@/utils/auth";

export function loginSms (params) {
  return request.post('user/api.user/smsLogin', params)
}

export function sendSmsCode (params) {
  return request.post('user/api.user/smsCode', params)
}
//获取邀请协助详情
export function lunciHelpInfo (params) {
  return request.post('/square/api.chat/lunciHelpInfo', params)
}

//确认加入邀请协助
export function joinHelpLunci (params) {
  return request.post('/square/api.chat/joinHelpLunci', params)
}

//参与的帮助轮次列表
export function helpLunciList () {
  return request.post('/square/api.chat/helpLunciList')
}

export async function getUserInfo () {

  let token = getToken()

  if (token) {
    let { data } = await request.post('user/api.userinfo/index')
    if (data.isLogin) {
      setSession(data)
      return true
    } else {
      false
    }
  } else {
    return false
  }
}

export async function updateUserInfo ({ headImgUrl, nickname }) {
  let res = await request.post('user/api.userinfo/update', { headImgUrl, nickname })
  if (res) {
    return true
  } else {
    return false
  }
}

export async function getLogingQR () {
  let { data } = await requestno.post('user/api.user/xiaoyiLoginCode', {
    merchantGuid: "e108201b02ae42e686bcc4c302cbbd11"
  })
  return data
}

export async function qrLoginCheck (loginCode) {
  let { data } = await request.post('/user/api.user/xiaoyiLoginCheck?msgId=msgId', { loginCode })
  return data
}

export async function getPayCode () {
  let { data } = await request.post('/user/api.user/xiaoyiPayCode?msgId=msgId', { "merchantGuid": "e108201b02ae42e686bcc4c302cbbd11" })
  return data.codeImg
}
//可购买的会员卡列表
export const getCardGoodsApi = (data) => request.post('/user/api.member/cardGoods', data);

// 购买会员卡
export const buyCardApi = (data) => request.post('/user/api.member/buyCard', data);

// 会员卡购买结果查询
export const getPayVipResultApi = (data) => request.post('/user/api.member/memberCardBuyQuery', data);

// 用户当前会员信息
export const getPuserVipInfoApi = (data) => request.post('/user/api.member/userVipInfo', data);

// 用户大会员（返佣会员）详情
export const getUserBigVipInfoApi = (data) => request.post('/user/api.member/userBigVipInfo', data);

// 申请大会员
export const applyBigVipApi = (data) => request.post('/user/api.member/applyBigVip', data);

// 申请大会员记录
export const bigVipApplyListApi = (data) => request.post('/user/api.member/bigVipApplyList', data);

// 提现账号列表
export const getAccountListApi = (data) => request.post('/user/api.member/withdrawAccountList', data);

// 新增提现账号
export const withdrawAccountAddApi = (data) => request.post('/user/api.member/withdrawAccountAdd', data);

// 删除提现账号
export const withdrawAccountDeleteApi = (data) => request.post('/user/api.member/withdrawAccountDelete', data);

// 发起提现申请
export const withdrawApplyApi = (data) => request.post('/user/api.member/withdrawApply', data);

// 大会员搜索可赠送AI点数的用户
export const searchUserApi = (data) => request.post('/user/api.member/searchUser', data);

// 大会员赠送AI点数
export const giveAiPointApi = (data) => request.post('/user/api.member/giveAiPoint', data);

// 大会员AI点数赠送记录
export const sendAiPointRecordApi = (data) => request.post('/user/api.member/sendAiPointRecord', data);

