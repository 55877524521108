<template>
	<div class="layout-chat-left">
		<div class="none-box" v-if="!_chatList.length > 0">暂无协助空间</div>
		<div
			class="chat-item"
			:class="{ active: item.chatLunciGuid === _chatingId }"
			v-for="item in _chatList"
			:key="item.guid"
		>
			<div class="delete-icon" @click="onExit(item)"><i class="el-icon-delete" size="24"></i></div>
			<div class="content" @click="onItem(item)">
				<p class="user">请求用户：{{ item.lunciUser.nickname }}</p>
				<p class="user">协助人员：{{ item.helpUserCount }}</p>
				<p class="user">聊天名称：{{ item.lunciInfo.chatLunciTitle }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { exitHelpLunci } from '@/apis/chat';

export default {
	name: 'layout-cooperation-left',

	props: {
		_chatingId: String,
		_chatList: Array,
	},

	data() {
		return {
			// chatList: [],
		};
	},
	computed: {},
	mounted() {
		// this.helpLunciList();
	},
	methods: {
		// async helpLunciList() {
		// 	let res = await helpLunciList();
		// 	this.chatList = res.data;
		// },
		async onExit(item) {
			this.$confirm('是否退出该协助创作', '退出协助', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
			})
				.then(async () => {
					await exitHelpLunci({ chatLunciGuid: item.chatLunciGuid });
					this.$message({
						message: '退出成功',
						type: 'success',
					});
					this.$emit('exitCooperation');
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					});
				});
		},
		onItem(item) {
			// this.activeClassId = item.id;
			if (item.chatLunciGuid === this._chatingId) {
				return;
			} else {
				this.$emit('changeActiveId', item.chatLunciGuid);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.layout-chat-left {
	width: 250px;
	flex-shrink: 0;
	border-right: solid 1px var(--border-color);
	padding: 10px;
	background: rgb(250 251 255);
	overflow-y: auto;
	box-sizing: border-box;
	.chat-item {
		padding: 10px 20px 10px 60px;
		border-bottom: 1px solid #e9e9e9;
		font-size: 14px;
		margin-top: 2px;
		position: relative;
		margin-bottom: 10px;
		border-radius: 5px;

		&.active {
			background-color: var(--primary-light);
			border: 1px solid var(--primary);
		}
		.delete-icon {
			width: 39px;
			height: 100%;
			position: absolute;
			left: 0px;
			top: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-right: 1px dashed #afafaf;
			cursor: pointer;
		}
		.content {
			cursor: pointer;
			p {
				overflow: hidden; /* 确保超出容器的文本被隐藏 */
				white-space: nowrap; /* 防止文本换行 */
				text-overflow: ellipsis; /* 超出部分显示省略号 */
			}
		}
	}
}
.none-box {
	font-size: 16px;
	padding: 30px;
}
@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.layout-chat-left {
		width: 200px;
		.chat-item {
			padding: 4px;
			margin-bottom: 40px;
			position: relative;
			// flex-direction: column;
			// align-items: start;
			.name {
				font-size: 12px;
			}
			.chat-icon-box {
				position: absolute;
				bottom: -28px;
				left: 0px;
				width: 100%;
			}
			.opts {
				width: 100%;
				display: flex;
				justify-content: space-around;
			}
		}
	}
}
</style>
