<template>
	<el-main class="layout-draw">
		<el-container>
			<layout-draw-left
				@showImages="setImages"
				:_status="status"
				@changeStatus="changeStatus"
				@updateConfig="updateConfig"
			></layout-draw-left>
			<layout-draw-main :_images="genImages" :_status="status"></layout-draw-main>
		</el-container>
	</el-main>
</template>

<script>
import LayoutDrawLeft from '@/views/app/layout-draw/layout-draw-left.vue';
import LayoutDrawMain from '@/views/app/layout-draw/layout-draw-main.vue';

export default {
	name: 'layout-draw',
	components: { LayoutDrawMain, LayoutDrawLeft },

	async mounted() {},

	data() {
		return {
			genImages: [],
			status: '0', // 0 - 未处理，1 - 处理中，2 - 已经完成
			config: {},
		};
	},

	methods: {
		setImages(imgs) {
			this.genImages = imgs;
		},

		changeStatus(status) {
			this.status = status;
		},

		updateConfig(config) {
			this.config = config;
		},
	},
};
</script>

<style lang="scss">
.layout-draw {
	//border-right: solid 1px var(--border-color);
	padding: 0 !important;
}
</style>
