<template>

    <el-main class="layout-chat">
        <el-container>
            <layout-chat-left @reloadList="loadChatList" :_chatList="chatList" :_chatingId.sync="chatingId"
                              @createChat="createChat"></layout-chat-left>
            <layout-chat-main :_chatObj="chatObj" :_chatDialogs="chatDialogs" @clearChatDialogs="clearChatDialogs"
                              :_chatingId="chatingId"></layout-chat-main>
        </el-container>
    </el-main>

</template>

<script>

import LayoutChatLeft from "@/views/app/layout-chat/layout-chat-left.vue";
import LayoutChatMain from "@/views/app/layout-chat/layout-chat-main.vue";
import { createChat, getChatDialogs, getChatList, getNowChat } from "@/apis/chat";

export default {
    name: "layout-chat",
    components: {LayoutChatMain, LayoutChatLeft},

    async mounted() {
        await this.loadChatList()
        this.chatingId = await getNowChat()
        // this.chatDialogs = await getChatDialogs({chatLunciGuid: this.chatingId})
    },

    data() {
        return {
            input: '',
            chatList: [],
            chatingId: '',
            chatDialogs: []
        }
    },

    methods: {

        clearChatDialogs() {
            this.chatDialogs = []
        },

        async loadChatList() {
            this.chatList = await getChatList()
            this.chatList.forEach(item => {
                item.visible = true
            })
        },

        async createChat() {
            await createChat()
            await this.loadChatList()
            this.chatingId = await getNowChat()
        }
    },

    computed: {
        chatObj() {
            let obj = this.chatList.find(item => item.guid == this.chatingId)
            return obj || null
        }
    },

    watch: {
        async chatingId(id) {
            this.chatDialogs = await getChatDialogs({chatLunciGuid: id})
        }
    }

}
</script>

<style lang="scss">
.layout-chat {
    padding: 0 !important;
}
</style>
