<template>
	<div class="layout-gallery-main pd10">
		<!--        <div class="xtitle">我的画廊</div>-->

		<div class="flex" style="flex-wrap: wrap" v-infinite-scroll="load">
			<el-card class="draw-style pointer" v-for="item of records" :key="item.guid">
				<div class="delete-box" @click="onDelete(item)">
					<i class="el-icon-delete" color="#FFFFFF"></i>
				</div>
				<el-image
					:src="item.img"
					fit="cover"
					v-if="item.workStatus == 'success'"
					@click.native="showDialogImage(item)"
				>
					<div slot="placeholder" class="image-slot">
						<i class="el-icon-loading"></i>
					</div>
				</el-image>

				<div
					class="image-slot"
					style="color: var(--primary)"
					v-if="item.workStatus == 'doing' || item.workStatus == 'wait'"
				>
					<img src="/imgs/status/doing.png" alt="" />
				</div>
				<!-- slot="placeholder" -->
				<div class="image-slot" v-if="item.workStatus == 'fail'">
					<img src="/imgs/status/fail.png" alt="" />
				</div>

				<div class="title-bg">
					<div class="mt10">{{ item.title }}</div>
				</div>
			</el-card>
		</div>

		<el-dialog class="img-dialog" :visible.sync="dialogVisible" :fullscreen="true">
			<div class="flex-column">
				<el-image class="center" :src="dialogImage" fit="contain"></el-image>

				<div class="center mt10">
					{{ dialogTitle }}
				</div>

				<div class="center mt10">
					<el-link @click="openLink(dialogImage)" type="success">浏览器打开</el-link>
				</div>

				<div class="btn-opt mt20 mb30" v-if="imgOpts">
					<el-row v-if="imgOpts.ableU_items">
						<el-button
							:disabled="opt.is_use == 1"
							@click="createImage(opt)"
							v-for="(opt, index) of imgOpts.ableU_items"
							:key="index"
							type="primary"
							size="mini"
							plain
							>放大 {{ opt.label }}
						</el-button>
					</el-row>
					<el-row class="mt10" v-if="imgOpts.ableV_items">
						<el-button
							:disabled="opt.is_use == 1"
							@click="createImage(opt, 'change')"
							v-for="(opt, index) of imgOpts.ableV_items"
							:key="index"
							type="primary"
							size="mini"
							plain
							>变化 {{ opt.label }}
						</el-button>
					</el-row>
				</div>
			</div>

			<div class="mt20">
				<el-button style="width: 30%" round type="success" @click="dialogVisible = false">关闭</el-button>
			</div>

			<!--            <span slot="footer" class="dialog-footer" style="text-align:center;!important">-->
			<!--                    <el-button type="primary" @click="dialogVisible = false">关闭</el-button>-->
			<!--            </span>-->
		</el-dialog>
	</div>
</template>

<script>
import {
	createImgOrderChange,
	createImgOrderUpscale,
	getWorkImages,
	queryDrawOrderChange,
	recordDelApi,
} from '@/apis/draw';

export default {
	name: 'layout-gallery-main',

	async mounted() {
		// this.records = await getWorkImages()
		console.log('records : ', this.records);
	},

	data() {
		return {
			records: [],
			currPage: 0,
			currentDate: new Date(),

			dialogVisible: false,
			dialogImage: '',
			dialogTitle: '',
			imgOpts: '',
			dialogOrderId: '',
		};
	},

	methods: {
		//删除
		async onDelete(item) {
			await recordDelApi({ recordGuid: item.guid });
			let moreImages = await getWorkImages(1);
			this.records = moreImages;
			this.$message({ message: '删除成功', type: 'success' });
		},
		async load() {
			let p = this.currPage + 1;
			let moreImages = await getWorkImages(p);

			if (moreImages.length > 0) {
				this.records.push(...moreImages);
				this.currPage++;
			}
		},

		async createImage({ custom_id }, m) {
			this.$confirm('生成图片将消耗1个算力, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(async () => {
				let orderId;
				if (m == 'change') {
					orderId = await createImgOrderChange(this.dialogOrderId, custom_id);
				} else {
					orderId = await createImgOrderUpscale(this.dialogOrderId, custom_id);
				}

				if (orderId) {
					this.$router.push('/app/draw?wait_order_id=' + orderId);
				} else {
					alert('出现错误，orderId为空');
				}
			});
		},

		openLink(link) {
			window.open(link, '_blank');
		},

		// 显示图片
		async showDialogImage({ orderNo, img, title }) {
			this.imgOpts = await queryDrawOrderChange(orderNo);
			this.dialogOrderId = orderNo;
			this.dialogImage = img;
			this.dialogTitle = title;
			this.dialogVisible = true;
		},
	},
};
</script>

<style lang="scss">
.delete-box {
	position: absolute;
	right: 20px;
	top: 20px;
	padding: 10px;
	background-color: rgb(0 0 0 / 20%);
	border-radius: 4px;
	z-index: 99;
	color: #fff;
	&:hover {
		background-color: rgb(0 0 0 / 10%);
	}
}
.layout-gallery-main {
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
	.el-card {
		flex: 0 0 calc(25% - 10px);
		margin: 4px;
		position: relative;
		max-height: 500px;
		.el-card__body {
			padding: 10px;
			height: 100%;
		}

		&:hover {
			.title-bg {
				display: block;
			}
		}
	}

	.header-img {
		width: 40px;
		height: 40px;
		border-radius: 100%;
	}

	.el-image {
		width: 100%;
		height: calc(100% - 10px);

		img {
			height: calc(100% - 10px);
		}
	}

	.title-bg {
		background: rgb(0 0 0 / 80%);
		position: absolute;
		display: none;
		height: 12%;
		width: 80%;
		z-index: 1;
		bottom: 18px;
		color: white;
		font-size: 13px;
		padding: 20px;
		left: 19px;
		border-radius: 10px;
	}

	.xtitle {
		text-align: center;
		font-size: 30px;
		background: linear-gradient(to right, red, blue);
		-webkit-background-clip: text;
		color: transparent;
		margin-bottom: 30px;
	}

	.img-dialog {
		.el-dialog__body {
			padding: 0 10px;
			text-align: center;
		}

		.el-image {
			img {
				max-height: 600px;
			}
		}
	}
}
</style>
