import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';  //导入组件
// import 'element-ui/lib/theme-chalk/index.css';  //导入样式
import "@/assets/theme/index.css"
import FeatherIcon from '@/components/FeatherIcon.vue'
import VWave from 'v-wave'
import VueClipboard from 'vue-clipboard2'
import store from './store'
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
Vue.use(VMdEditor);
Vue.use(VMdPreview);
Vue.use(VueClipboard)
Vue.use(ElementUI, { size: 'small' })
Vue.use(VWave)
Vue.prototype.$store = store
// Vue.use(store)
Vue.config.productionTip = false
Vue.component(FeatherIcon.name, FeatherIcon)


import VueRouter from 'vue-router'
Vue.use(VueRouter)

import router from './router' // 引入路由配置

// ------
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)
// ------



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
