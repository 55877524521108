<script>
import {
	getPuserVipInfoApi,
	getUserBigVipInfoApi,
	applyBigVipApi,
	bigVipApplyListApi,
	getAccountListApi,
	withdrawAccountAddApi,
	withdrawAccountDeleteApi,
	withdrawApplyApi,
	searchUserApi,
	giveAiPointApi,
	sendAiPointRecordApi,
} from '@/apis/user';
import PayMealDialog from '@/views/app/dialogs/pay-meal-dialog.vue';

export default {
	components: { PayMealDialog },
	data() {
		return {
			vipInfo: {
				cardName: '',
				cardDesc: '',
				cardEndTime: '',
			},
			bigVipInfo: {
				isBigVip: false,
				isAbleApply: false,
				bigVipInfo: {
					balance: '',
					aiPoint: '',
					balanceTotal: '',
				},
			},
			applyFormVisible: false,
			applyListVisible: false,
			applyForm: {
				applyRemark: '',
			},
			applyList: [],
			addCashOutVisible: false,
			cashOutListVisible: false,
			cashOutVisible: false,
			showGiveVisible: false,
			giveHistoryVisible: false,
			addCashOutForm: {
				withdrawMethod: '1',
				withdrawAccount: '',
				withdrawName: '',
				bankName: '',
				bankBranchName: '',
			},
			cashOutForm: {
				withdraw_method_guid: '',
				withdraw_amount: 1,
				withdraw_remark: '',
			},
			givePrintForm: {
				keywords: '',
				giveUserGuid: '',
				aiPoint: 1,
			},
			giveUsers: [],
			giveHistory: [],
			accountList: [],
			invitationPath: '',
		};
	},
	mounted() {
		this.getUserVipInfo();
		this.getUserBigVipInfo();
		console.log(window.location.href, '---window.location.href');
	},
	methods: {
		async getUserVipInfo() {
			let res = await getPuserVipInfoApi();
			this.vipInfo.cardDesc = res.data.cardInfo.cardDesc;
			this.vipInfo.cardName = res.data.cardInfo.cardName;
			this.vipInfo.cardEndTime = res.data.vipInfo.cardEndTime;
		},
		async getUserBigVipInfo() {
			let res = await getUserBigVipInfoApi();
			if (res.code === 0) {
				this.bigVipInfo = res.data;
				// this.invitationPath= window.location.href
				this.invitationPath = `https://xiaoyi.aiaskbot.cn/?inviteCode=${res.data.bigVipInfo.inviteCode}&isBigVip=1`;
			} else {
				this.invitationPath = '邀请链接生成失败';
			}
		},
		onShowApplyVip() {
			this.applyFormVisible = true;
		},
		async onShowApplyRecord() {
			let res = await bigVipApplyListApi();
			this.applyList = res.data;
			this.applyListVisible = true;
		},
		async onApply() {
			let res = await applyBigVipApi({ applyRemark: this.applyForm.applyRemark });
			if (res.code === 0) {
				this.$message({
					message: '申请成功',
					type: 'success',
				});
				this.applyFormVisible = false;
			} else {
				this.$message({
					message: res.msg,
					type: 'info',
				});
			}
		},
		onCashOut() {
			// this.cashOutVisible = true;
			this.cashOutListVisible = true;
			this.getAccountList();
		},
		handleClick() {},
		async getAccountList() {
			let res = await getAccountListApi();
			this.accountList = res.data;
		},
		async onSubmitPayMode() {
			if (this.addCashOutForm.withdrawMethod == 1 || this.addCashOutForm.withdrawMethod == 2) {
				if (
					this.addCashOutForm.withdrawAccount.trim().length > 0 &&
					this.addCashOutForm.withdrawName.trim().length > 0
				) {
					await withdrawAccountAddApi(this.addCashOutForm);
					this.getAccountList();
					this.addCashOutVisible = false;
					this.cashOutListVisible = true;
					this.$message({
						message: '新增成功',
						type: 'success',
					});
				} else {
					this.$message({
						message: '请完善账号信息',
						type: 'info',
					});
				}
			} else if (this.addCashOutForm.withdrawMethod == 3) {
				if (
					this.addCashOutForm.withdrawAccount.trim().length > 0 &&
					this.addCashOutForm.withdrawName.trim().length > 0 &&
					this.addCashOutForm.bankName.trim().length > 0 &&
					this.addCashOutForm.bankBranchName.trim().length > 0
				) {
					// this.clearYH();
					await withdrawAccountAddApi(this.addCashOutForm);
					this.getAccountList();
					this.addCashOutVisible = false;
					this.cashOutListVisible = true;
					this.$message({
						message: '新增成功',
						type: 'success',
					});
				} else {
					this.$message({
						message: '请完善账号信息',
						type: 'info',
					});
				}
			}
		},
		clearYH() {
			this.addCashOutForm.bankName = '';
			this.addCashOutForm.bankBranchName = '';
		},
		async onWithdrawal(guid) {
			this.cashOutForm.withdraw_method_guid = guid;
			this.cashOutVisible = true;
		},
		async onDeleteMode(item) {
			await withdrawAccountDeleteApi({ guid: item.guid });
			this.$message({
				message: '删除成功',
				type: 'success',
			});
			this.getAccountList();
		},
		onShowAddDialog() {
			this.cashOutListVisible = false;
			this.addCashOutVisible = true;
		},
		showPayMeal() {
			this.$refs.paymeal.show();
		},
		onPricechange(res) {
			this.cashOutForm.withdraw_amount = res.toFixed(0);
		},
		onPrintchange(res) {
			this.givePrintForm.aiPoint = res.toFixed(0);
		},
		//发起提现申请
		async onAddCashSubmit() {
			try {
				let res = await withdrawApplyApi(this.cashOutForm);
				if (res.code === 0) {
					this.$message({
						message: '提现申请成功',
						type: 'success',
					});
					this.cashOutVisible = false;
				} else {
					this.$message({
						message: res.msg,
						type: 'info',
					});
				}
			} catch (error) {}
		},
		onShowGivePrint() {
			this.showGiveVisible = true;
		},
		async onSeachUser() {
			if (!this.givePrintForm.keywords) {
				return;
			}
			let res = await searchUserApi({
				keywords: this.givePrintForm.keywords,
			});
			this.giveUsers = res.data;
		},
		async onGiveSubmit() {
			if (!this.givePrintForm.giveUserGuid) {
				return;
			}
			let res = await giveAiPointApi(this.givePrintForm);
			if (res.code === 0) {
				this.$message({
					message: '赠送成功',
					type: 'success',
				});
				this.showGiveVisible = false;
			} else {
				this.$message({
					message: res.msg,
					type: 'info',
				});
			}
		},
		async onGiveHistory() {
			this.giveHistoryVisible = true;
			let res = await sendAiPointRecordApi();
			this.giveHistory = res.data;
		},
		onCopyIntPath() {
			this.$copyText(this.invitationPath);
			this.$message({ message: '已复制邀请链接', type: 'success' });
		},
	},
};
</script>

<template>
	<el-main class="layout-big-vip">
		<div class="now-vip">
			<div class="vip-info">
				<p class="card-name">{{ vipInfo.cardName }}</p>
				<p class="card-date">权益到期时间：{{ vipInfo.cardEndTime }}</p>
			</div>
			<div class="vip-notice" v-html="vipInfo.cardDesc"></div>
		</div>
		<el-card class="vip-config" shadow="hover" v-if="bigVipInfo.isBigVip">
			<p class="title">我的大会员：</p>
			<div class="item">
				<div class="lable">可提现金额：￥{{ bigVipInfo.bigVipInfo.balance }}</div>
				<div class="btn-box">
					<div class="button" @click="onCashOut">提现</div>
				</div>
			</div>
			<div class="item">
				<div class="lable">助理算力：{{ bigVipInfo.bigVipInfo.aiPoint }}</div>
				<div class="btn-box">
					<div class="button" @click="showPayMeal">采购算力</div>
					<div class="button" @click="onShowGivePrint">赠送算力</div>
				</div>
			</div>
			<div class="item">
				<div class="lable">累积分佣金额：￥{{ bigVipInfo.bigVipInfo.balanceTotal }}</div>
			</div>
			<div class="item">
				<div class="lable">
					分佣邀请链接：<span class="invitationText">{{ invitationPath }}</span>
				</div>
				<div class="btn-box">
					<div class="button" @click="onCopyIntPath">复制邀请链接</div>
				</div>
			</div>
		</el-card>
		<el-card class="vip-desc" shadow="hover" v-if="!bigVipInfo.isBigVip">
			<p class="title">大会员权益：</p>
			<div class="info">
				<p class="li">
					大会员开通规则：年费用户可申请成为大会员，审批通过后即可成为大会员。大会员拥有一下权益：
				</p>
				<p class="li">1、大会员享受更低的AI点数购买资格，AI点数赠送资格</p>
				<p class="li">2、邀请用户购买，会员返佣30%的权益，返佣金额可提现或购买AI点数</p>
				<p class="li">3、进入合作者专属社区</p>
			</div>
			<div class="btn-box" v-if="bigVipInfo.isAbleApply">
				<div class="button" @click="onShowApplyVip">申请开通大会员</div>
				<div class="button-record" @click="onShowApplyRecord">申请记录</div>
			</div>
		</el-card>
		<el-dialog title="申请大会员" :visible.sync="applyFormVisible">
			<el-form :model="applyForm">
				<el-form-item label="申请备注" label-width="80px">
					<el-input v-model="applyForm.applyRemark" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="onApply">申 请</el-button>
			</div>
		</el-dialog>
		<el-dialog title="申请记录" :visible.sync="applyListVisible">
			<el-table :data="applyList">
				<el-table-column
					property="applyRemark"
					:show-overflow-tooltip="true"
					label="申请备注"
					width="200"
				></el-table-column>
				<el-table-column property="refuseReason" label="拒绝理由" width="200"></el-table-column>
				<el-table-column property="applyStatusText" label="申请状态"></el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog title="新增提现账号" :visible.sync="addCashOutVisible" width="600px">
			<el-tabs v-model="addCashOutForm.withdrawMethod">
				<el-tab-pane label="微信" name="1">
					<el-input
						v-model="addCashOutForm.withdrawAccount"
						size="medium"
						placeholder="请输入微信账号"
					></el-input>
					<div class="mt10">
						<el-input
							size="medium"
							v-model="addCashOutForm.withdrawName"
							placeholder="请输入收款人姓名"
						></el-input>
					</div>
				</el-tab-pane>
				<el-tab-pane label="支付宝" name="2">
					<el-input
						size="medium"
						v-model="addCashOutForm.withdrawAccount"
						placeholder="请输入支付宝账号"
					></el-input>
					<div class="mt10">
						<el-input
							size="medium"
							v-model="addCashOutForm.withdrawName"
							placeholder="请输入收款人姓名"
						></el-input>
					</div>
				</el-tab-pane>
				<el-tab-pane label="银行卡" name="3">
					<el-input
						size="medium"
						v-model="addCashOutForm.withdrawAccount"
						placeholder="请输入银行卡账号"
					></el-input>
					<div class="mt10">
						<el-input
							size="medium"
							v-model="addCashOutForm.withdrawName"
							placeholder="请输入收款人姓名"
						></el-input>
					</div>
					<div class="mt10">
						<el-input size="medium" v-model="addCashOutForm.bankName" placeholder="收款银行"></el-input>
					</div>
					<div class="mt10">
						<el-input
							size="medium"
							v-model="addCashOutForm.bankBranchName"
							placeholder="请输入收款银行支行"
						></el-input>
					</div>
				</el-tab-pane>
			</el-tabs>
			<div class="mt10">
				<el-button type="primary" @click="onSubmitPayMode">提 交</el-button>
			</div>
		</el-dialog>
		<el-dialog title="提现列表" :visible.sync="cashOutListVisible" width="600px">
			<el-button @click="onShowAddDialog" type="primary" size="small"> 新增提现账号 </el-button>
			<el-table :data="accountList" empty-text="左上角新增提现账号">
				<el-table-column property="withdrawMethod" label="提现方式" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.withdrawMethod == 1">微信提现</div>
						<div v-if="scope.row.withdrawMethod == 2">支付宝提现</div>
						<div v-if="scope.row.withdrawMethod == 3">银行卡提现</div>
					</template>
				</el-table-column>
				<el-table-column property="withdrawAccount" label="账号" width="150"></el-table-column>
				<el-table-column property="withdrawName" label="收款人" width="150"></el-table-column>
				<el-table-column fixed="right" label="操作">
					<template slot-scope="scope">
						<!-- <el-popconfirm
							title="确认删除该提现方式吗？"
							icon="el-icon-info"
							icon-color="red"
							@confirm="onDeleteMode(scope.row)"
						> -->
						<el-button type="text" size="small" @click="onDeleteMode(scope.row)"> 删 除 </el-button>
						<!-- </el-popconfirm> -->
						<el-button @click="onWithdrawal(scope.row.guid)" type="text" size="small"
							>使用该账号提现</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog title="发起提现申请" :visible.sync="cashOutVisible" width="500px">
			<el-form ref="form" :model="cashOutForm" label-width="80px">
				<el-form-item label="提现金额">
					<el-input-number
						v-model="cashOutForm.withdraw_amount"
						controls-position="right"
						@change="onPricechange"
						:min="1"
					></el-input-number>
					<span> (请输入整数)可提现金额：￥{{ bigVipInfo.bigVipInfo.balance }}</span>
				</el-form-item>
				<el-form-item label="备注">
					<el-input
						size="medium"
						v-model="cashOutForm.withdraw_remark"
						placeholder="请输入备注（选填）"
					></el-input>
				</el-form-item>
			</el-form>
			<div class="mt10">
				<el-button type="primary" @click="onAddCashSubmit">提 交</el-button>
			</div>
		</el-dialog>

		<el-dialog title="赠送算力" :visible.sync="showGiveVisible" width="600px">
			<el-form ref="form" :model="givePrintForm" label-width="80px">
				<el-form-item label="用户名">
					<el-input size="medium" v-model="givePrintForm.keywords" placeholder="请输入用户名">
						<el-button slot="append" icon="el-icon-search" @click="onSeachUser"></el-button
					></el-input>
				</el-form-item>
				<el-form-item label="赠送算力">
					<el-input-number
						v-model="givePrintForm.aiPoint"
						controls-position="right"
						@change="onPrintchange"
						:min="1"
						:max="bigVipInfo.bigVipInfo.aiPoint"
					></el-input-number>
				</el-form-item>
				<el-form-item label="选择用户">
					<div v-if="giveUsers.length > 0">
						<el-radio
							v-model="givePrintForm.giveUserGuid"
							:label="item.guid"
							v-for="item in giveUsers"
							:key="item.guid"
							>{{ item.nickname }}</el-radio
						>
					</div>
					<p v-else>请搜索想要赠送的用户</p>
				</el-form-item>
			</el-form>
			<div class="mt10">
				<el-button type="primary" @click="onGiveSubmit">赠 送</el-button>
				<el-button type="info" @click="onGiveHistory">记 录</el-button>
			</div>
		</el-dialog>
		<el-dialog title="赠送记录" :visible.sync="giveHistoryVisible" width="600px">
			<el-table :data="giveHistory" empty-text="暂无赠送记录">
				<el-table-column property="acceptUser.nickname" label="用户名"></el-table-column>
				<el-table-column property="sendAiPoint" label="赠送算力" width="100"></el-table-column>
				<el-table-column property="sendTime" label="赠送时间"></el-table-column>
			</el-table>
		</el-dialog>
		<pay-meal-dialog ref="paymeal"></pay-meal-dialog>
	</el-main>
</template>

<style lang="scss" scoped>
::v-deep .el-table thead {
	color: #383838;
}
::v-deep .el-dialog__body {
	padding: 5px 20px 30px 20px;
}
.vip-config {
	margin-top: 30px;
	.title {
		font-weight: bold;
		margin-bottom: 20px;
	}
	.item {
		width: 680px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;
		.lable {
			font-weight: bold;
			font-size: 14px;
		}
		.btn-box {
			display: flex;
		}
		.button {
			width: fit-content;
			padding: 0 10px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			color: #fff;
			margin-right: 6px;
			background: linear-gradient(
					230.47deg,
					rgb(255, 164, 28) -0.87%,
					rgb(254, 65, 65) 25.89%,
					rgb(244, 21, 62) 77.87%,
					rgb(255, 39, 195) 97.94%
				),
				rgb(231, 56, 40);
			border-radius: 4px;
			cursor: pointer;
		}
		.invitationText {
			font-weight: normal;
			text-decoration: underline;
		}
	}
}
.now-vip {
	display: flex;

	.vip-info {
		width: 340px;
		height: 185px;
		background-image: url('@/static/icon/vip-bg.png');
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 30px 20px;
		box-sizing: border-box;
		.card-name {
			font-size: 24px;
			color: #282a36;
			font-weight: bold;
		}
		.card-date {
			text-align: right;
			color: #2e4bbf;
		}
	}
	.vip-notice {
		padding: 20px;
		box-sizing: border-box;
	}
}
.vip-desc {
	margin-top: 30px;
	.title {
		font-weight: bold;
		margin-bottom: 20px;
	}
	.li {
		margin-top: 4px;
		color: #000000;
		font-size: 14px;
	}
	.btn-box {
		display: flex;
		margin-top: 10px;
		justify-content: end;
		.button {
			width: fit-content;
			padding: 0 10px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			color: #fff;
			margin-right: 6px;
			background: linear-gradient(
					230.47deg,
					rgb(255, 164, 28) -0.87%,
					rgb(254, 65, 65) 25.89%,
					rgb(244, 21, 62) 77.87%,
					rgb(255, 39, 195) 97.94%
				),
				rgb(231, 56, 40);
			border-radius: 4px;
			cursor: pointer;
		}
		.button-record {
			width: fit-content;
			padding: 0 10px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			color: #fff;
			margin-right: 6px;
			background: #6c6b6a;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
</style>
