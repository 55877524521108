import hljs from "highlight.js";

const MarkdownIt = require('markdown-it');
// const multimdTable = require('markdown-it-multimd-table');
var { markdownItTable } = require('markdown-it-table');


let copyIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="11px" height="11px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>'
let copiedIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>'

const mdOptions = {

    linkify: false,
    typographer: true,
    breaks: true,
    langPrefix: "language-",
    // highlight: (str, lang, attrs) => {
    //
    //     let content = str
    //
    //     if (lang && hljs.getLanguage(lang)) {
    //         try {
    //             content = hljs.highlight(str, {language: lang, ignoreIllegals: true}).value
    //         } catch (e) {
    //             return str
    //         }
    //     } else {
    //         content = md.utils.escapeHtml(str)
    //     }
    //
    //     // join actions html string
    //     lang = (lang || 'txt').toUpperCase()
    //
    //     return [
    //         '<div class="code-block-wrapper">',
    //         `<!--<div class="code-header"><span class="code-lang">${lang}</span></div>-->`,
    //         '<pre class="hljs code-block hljs-code">',
    //         `<code>${content}</code>`,
    //         '</pre>',
    //         '</div>',
    //     ].join('');
    // }

};

const md = new MarkdownIt(mdOptions)

// md.use(markdownItTable)

// md.use(multimdTable, {
//     multiline: false,
//     rowspan: false,
//     headerless: false,
//     multibody: true,
//     aotolabel: true,
// });

export { md }
