<template>
	<div class="chat-box">
		<div
			class="chat-item"
			:key="idx"
			v-for="(item, idx) of _chatDialogs"
			:class="item.chatRole != 'assistant' ? 'mine' : ''"
		>
			<div class="header-img ml10" v-if="item.chatRole != 'assistant'">
				<img v-if="session" :src="session.headImgUrl" alt="" />
			</div>

			<div class="header-img ml10" v-if="item.chatRole == 'assistant'">
				<img
					src="https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/76ab72d19e2249a28f77e2d488de8c3f.jpg"
					alt=""
				/>
			</div>

			<div class="content">
				<div v-show="item.starting" class="loading">思考中 ...</div>
				<!-- <div class="chat-content md-htm" v-html="renderHtml(item.chatContent)"></div> -->
				<v-md-preview v-show="!item.starting" class="preview-box" :text="item.chatContent"></v-md-preview>
				<el-image
					v-for="(img, idx) of item.imgUrls"
					:src="img"
					:preview-src-list="item.imgUrls"
					:key="idx"
				></el-image>
			</div>
			<div class="copy">
				<a class="ml10" @click="copy(item.chatContent)"> <i class="el-icon-document-copy"></i> </a>
				<a class="star ml10" v-if="item.chatRole != 'assistant'" @click="collection(item)">
					<i class="el-icon-star-off"></i>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { md } from '@/libs/markdown';
import 'highlight.js/scss/default.scss';
import 'highlight.js/styles/atom-one-dark.css';
import { getSession } from '@/utils/auth';
import { collectionChatSave } from '@/apis/chat';
export default {
	name: 'chat-box',

	props: {
		_chatDialogs: Array,
	},

	created() {
		this.session = getSession();
	},

	data() {
		return {
			session: null,
		};
	},

	methods: {
		renderHtml(str) {
			return md.render(str);
		},

		copy(str) {
			this.$copyText(str);
			this.$message({ message: '已复制', type: 'success' });
		},
		async collection(item) {
			let res = await collectionChatSave({ msgId: item.msgId });
			if (res.code === 0) {
				this.$message({ message: '收藏成功', type: 'success' });
			} else {
				this.$message({ message: '收藏失败', type: 'error' });
			}
		},
	},
};
</script>

<style>
.preview-box .github-markdown-body {
	padding: 0px;
	background-color: inherit;
	font-size: inherit;
	color: inherit;
}
:-webkit-any(article, aside, nav, section) h1 {
	font-size: 24px;
}

:-webkit-any(article, aside, nav, section) :-webkit-any(article, aside, nav, section) h1 {
	font-size: 24px;
}
</style>

<style lang="scss" scoped>
.chat-box {
	.el-image {
		background: white;
		max-width: 200px;
		max-height: 200px;
		margin: 4px;
	}

	.chat-item.mine {
		flex-direction: row-reverse;

		.content {
			background-color: var(--primary);
			color: white;
		}
	}

	.hljs {
		padding: 10px;
		border-radius: 6px;
	}

	.chat-item {
		display: flex;
		padding: 20px;
		box-sizing: border-box;
		.header-img {
			img {
				width: 40px;
				height: 40px;
				border-radius: 100%;
			}
		}

		.copy {
			font-size: 12px;
			display: flex;
			align-items: center;
			// justify-content: center;
			flex-direction: column;
			.star {
				margin-top: 10px;
				font-size: 14px;
				text-align: center;
			}
		}

		.content {
			background-color: #f1f5f9;
			border-radius: 6px;
			max-width: 750px;
			min-width: 60px;
			padding: 10px 12px;
			margin-left: 10px;
			font-size: 13px;
			word-wrap: break-word;
			overflow-wrap: break-word;
			white-space: normal;
			text-overflow: ellipsis;
		}
	}

	.md-htm {
		padding: 4px;

		h1 {
			font-size: 18px !important;
		}

		& > pre {
			padding: 0;
			margin-top: 6px;
			margin-bottom: 6px;
			background: transparent !important;
		}

		table {
			width: 100%;
			border-collapse: collapse;
			//color: #6e6b7b;
			margin: 10px;
			background: white;
		}

		table,
		th,
		td {
			border: 1px solid #ddd9e9;
		}

		th,
		td {
			padding: 4px 15px;
			text-align: left;
		}

		th {
			//background-color: #EFEFF4;
		}
	}
}
@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.chat-box {
		.chat-item {
			padding: 10px;
			.content {
				max-width: 75%;
			}
		}
	}
}
</style>
