import Vue from 'vue'
import VueRouter from 'vue-router'
import homePage from '@/views/account/login'
import appPage from '@/views/app/layout'

import layoutChat from '@/views/app/layout-chat/layout-chat.vue'
import layoutDraw from '@/views/app/layout-draw/layout-draw.vue'
import layoutGallery from '@/views/app/layout-gallery/layout-gallery.vue'
import LayoutTools from '@/views/app/layout-tools/layout-tools.vue'
import LayoutCooperation from '@/views/app/layout-cooperation/layout-cooperation.vue'
import LayoutBigvip from '@/views/app/layout-bigvip/layout-bigvip.vue'
import { getToken } from "@/utils/auth"
import { getUserInfo } from "@/apis/user"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: homePage
    },
    {
      path: '/app',
      name: 'app',
      component: appPage,
      children: [{
        path: 'chat',
        name: 'chat',
        component: layoutChat
      }, {
        path: 'draw',
        name: 'draw',
        component: layoutDraw
      }, {
        path: 'gallery',
        name: 'gallery',
        component: layoutGallery
      }, {
        path: 'tools',
        name: 'tools',
        component: LayoutTools
      }, {
        path: 'cooperation',
        name: 'cooperation',
        component: LayoutCooperation
      }, {
        path: 'bigvip',
        name: 'bigvip',
        component: LayoutBigvip
      }]
    }
  ]
})

router.beforeEach(async (page, from, next) => {

  const yqGuid = page.query.yqGuid
  if (yqGuid) {
    localStorage.setItem("yqGuid", yqGuid);
  }
  let u = getToken()

  if (u) {
    if (page.name == 'home') {
      next('/app/chat')
    } else {
      next()
    }
  } else {

    if (page.name != 'home') {
      next('/')
    } else {
      next()
    }
  }
})

router.afterEach(() => {

})

export default router
