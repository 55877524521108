import axios from 'axios'
import { Message } from 'element-ui'
// import router from '@/router/index'
import store from '@/store'
import { getToken, removeSession } from '@/utils/auth'
import { parseInt } from 'lodash'
import { randomStr, queryStr } from '@/libs/request-random'
import { autographFun } from '@/libs/request-generateSignature'

const service = axios.create({
  baseURL: 'https://ai-v2.deepcity.cn/',
  timeout: 5000, // request timeout
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [function (data) {
    return JSON.stringify(data) || {}
  }]
})

// service.interceptors.request.use(config => {
//         return config
//     },
//     error => {
//         console.log(error)
//         return Promise.reject(error)
//     }
// )

service.interceptors.response.use(
  response => {
    const res = response
    return res.data
  },
  error => {
    Message({
      message: error.msg ? error.msg : '当前访问人数较多，请稍后再试',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
