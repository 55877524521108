<template>
	<el-container>
		<layout-header></layout-header>
		<el-container style="padding-top: 39px; padding-left: 4px">
			<layout-sider></layout-sider>
			<el-container>
				<router-view></router-view>
			</el-container>
		</el-container>
	</el-container>
</template>

<script>
import LayoutSider from '@/views/app/layout-sider.vue';
import LayoutHeader from '@/views/app/layout-header.vue';
import LayoutDraw from '@/views/app/layout-draw/layout-draw.vue';
import LayoutChat from '@/views/app/layout-chat/layout-chat.vue';
import LayoutGallery from '@/views/app/layout-gallery/layout-gallery.vue';
import LayoutTools from '@/views/app/layout-tools/layout-tools.vue';

export default {
	name: 'layout',
	components: { LayoutGallery, LayoutChat, LayoutDraw, LayoutHeader, LayoutSider, LayoutTools },

	mounted() {},

	data() {
		let pageName = this.$route.params.pageName;
		return { pageName };
	},
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

.layout {
	border: var(--border-in-light);
	border-radius: 20px;
	box-shadow: var(--shadow);
	color: var(--black);
	background-color: var(--white);
	min-width: 600px;
	min-height: 370px;
	max-width: 1200px;
	display: flex;
	overflow: hidden;
	box-sizing: border-box;
	//width: var(--window-width);

	width: 80vw;
	height: var(--window-height);

	.main-container {
		margin-top: 30px;
	}
}
</style>
