<template>
	<div class="layout-tools-left">
		<div class="tab-box">
			<div
				:class="['tab-item', { active: sceneListId === item.id }]"
				v-for="(item, index) in classList"
				:key="index"
				@click="onChengOptions(item)"
			>
				{{ item.title }}
			</div>
		</div>
		<div class="content-list">
			<div :class="['item-box',{'active':activeClassId===item.id}]" v-for="item in contentList">
				<img class="image" :src="item.image" @click="onItem(item)"></img>
				<div class="title-box">
					<div class="title">
						{{ item.title }}
					</div>
					<div class="collect" @click="onCollect(item)">
						<i :class="['el-icon-star-off', { 'el-icon-star-on': item.isCollect }]"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getClassListApi, collectClassListApi, getClassContentListApi,cancelClassApi,collectClassApi } from '@/apis/tools';

export default {
	name: 'layout-chat-left',
	props: {},
	data() {
		return {
			sceneListId: '',
      activeClassId:'',
			classList: [
				{
					id: 0,
					title: '我的收藏',
				},
			],
			contentList: [],
		};
	},
	mounted() {
		this.getClassList();
	},
	methods: {
		async getClassList() {
			let res = await getClassListApi({ cateType: 'text', merchantGuid: 'e108201b02ae42e686bcc4c302cbbd11' });
			this.classList.push(...res.data);
			this.sceneListId = res.data[0].id;
      this.getClassContentList();
		},
		onChengOptions(item) {
			this.sceneListId = item.id;
			if (item.id === 0) {
				this.collectClassList();
			} else {
				this.getClassContentList();
			}
		},
		async collectClassList() {
			let res = await collectClassListApi({ cateType: 'text' });
			let list = [];
			res.data.forEach(item => {
				if (item.categoryInfo) {
          item.categoryInfo.isCollect=true;
					list.push(item.categoryInfo);
				}
			});
			this.contentList = list;
		},
		async getClassContentList() {
			let req = {
				cateId: this.sceneListId,
			};
			let res = await getClassContentListApi(req);
			this.contentList = res.data;
      if(!this.activeClassId && res.data.length>0){
        console.log(res.data,'res.datares.datares.data')
        this.activeClassId = res.data[0].id;
        this.$emit('changeActiveClassId',this.activeClassId)
      }
		},
    onItem(item) {
      this.activeClassId = item.id;
      this.$emit('changeActiveClassId',item.id)
    },
    async onCollect(item) {
      if (this.activeClassId === 0) {
        await cancelClassApi({ cateId: item.id });
        this.$message({
          message: '取消收藏',
          type: 'success'
        });
      } else {
        if (item.isCollect) {
          await cancelClassApi({ cateId: item.id });
          this.$message({
          message: '取消收藏',
          type: 'success'
        });
        } else {
          console.log(item,'itemitemitemitem')
          await collectClassApi({ cateId: item.id });
          this.$message({
          message: '收藏成功',
          type: 'success'
        });
        }
      }
      if (this.activeClassId === 0) {
        this.collectClassList();
      } else {
        this.getClassContentList();
      }
    },
	},
};
</script>

<style lang="scss">
.layout-tools-left {
	width: 300px;
	border-right: solid 1px var(--border-color);
	padding: 10px;
	background: rgb(250 251 255);
	overflow-y: auto;
	box-sizing: border-box;
	.tab-box {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;

		.tab-item {
			width: 50%;
			text-align: center;
      font-size:16px;
			padding: 10px 0px;
			cursor: pointer;
			border-bottom: 1px solid var(--border-color);
			position: relative;
			&.active::after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 50%;
				transform: translateX(-50%);
				width: 30px;
				height: 3px;
				border-radius: 2px;
				background-color: var(--primary);
			}
		}
	}
	.content-list {
		display: flex;
		flex-wrap: wrap;
    justify-content:space-between;
		.item-box {
			width: 48%;
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;
      padding: 4px;
      border-radius: 4px;
      box-sizing: border-box;
      &.active{
        border: 1px solid var(--primary);;
      }
			.image {
				width: 100%;
				height: 150px;
				border-radius: 4px;
        cursor: pointer;
			}
			.title-box {
				display: flex;
				justify-content: space-between;
				padding: 6px;
				.title {
					font-size: 14px;
				}
				.collect {
					cursor: pointer;
					i {
						font-size: 18px;
						color: var(--primary);
						&.el-icon-star-on {
							color: #fff;
							background-color: var(--primary);
							border-radius: 50%;
							padding: 2px;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.layout-tools-left {
		width: 200px;
		.tab-box{
      margin-bottom: 20px;
      .tab-item{
        font-size: 14px;
      }
    }
    .content-list{
      .item-box{
        .image {
				  height: 100px;
			  }
        .title-box{
          .title{
            font-size: 12px;
          }
        }
      }
    }
	}
}
</style>
