<template>
	<div class="layout-chat-main">
		<div class="chat-header" v-if="_chatObj">
			<span>{{ _chatObj.lunciUser.nickname }}的聊天（协助模式中）</span>
		</div>
		<chat-box id="chatbox" ref="chatBox" :_chatDialogs="_chatDialogs"></chat-box>
		<div class="chat-input">
			<div class="opt-panel mb4">
				<el-dropdown trigger="click" class="mr4">
					<el-button size="mini" round class="el-dropdown-link" style="padding: 6px 10px; margin-bottom: 0">
						<i class="iconfont icon-setting mr4"></i> 选择AI模型 ：{{ selectModel.name }}
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item
							@click.native="choooseModel(value)"
							v-for="{ name, value } of models"
							:key="value"
							>{{ name }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-dropdown trigger="click" class="mr4">
					<el-button round class="el-dropdown-link" style="padding: 6px 10px; margin-bottom: 0">
						<i class="iconfont icon-robot2 mr4"></i> 选择AI角色 ：{{ selectRole.name }}
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-for="item of roles" :key="item.id">
							<div class="flex role-drop-box">
								<div class="role-drop-title mr-4" @click="choooseRole(item)">
									{{ item.name }}
								</div>
								<el-button
									size="mini"
									icon="el-icon-edit"
									v-if="item.isEdit"
									@click="onEditRole(item)"
								></el-button>
								<el-button
									size="mini"
									icon="el-icon-delete"
									v-if="item.isDelete"
									@click="onDeleteRole(item)"
								></el-button>
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-button @click="addRolePop" size="mini" round class="el-dropdown-link">
					<i class="iconfont el-icon-circle-plus-outline mr4"></i> 新增协作角色
				</el-button>
				<el-button @click="cleanDialogs" size="mini" round class="mr4">
					<i class="iconfont el-icon-brush mr4"></i> 清除聊天记录
				</el-button>
				<el-button
					:disabled="!isSupportImg"
					v-if="msgImgsCount == 0"
					size="mini"
					round
					style="padding: 5px 10px"
					class="ml4"
					@click="imgInputVisible = true"
				>
					<i class="el-icon-upload2"></i> 图像
				</el-button>

				<el-badge :value="msgImgsCount" v-if="msgImgsCount > 0" class="ml4">
					<el-button
						:disabled="!isSupportImg"
						size="mini"
						round
						style="padding: 5px 10px"
						@click="imgInputVisible = true"
					>
						<i class="el-icon-upload2"></i> 图像
					</el-button>
				</el-badge>

				<el-button type="success" style="margin-left: auto" class="ml4" size="mini" @click="waitingImage">
					<i class="el-icon-picture mr4"></i> 绘画
				</el-button>
			</div>

			<div class="flex">
				<div class="input-box">
					<el-input
						class="mr10"
						type="textarea"
						:rows="4"
						placeholder="请输入内容"
						v-model="textarea"
						resize="none"
					></el-input>
					<el-button
						size="mini"
						round
						class="el-dropdown-link"
						style="padding: 6px 10px; margin-bottom: 0"
						@click="isPrviewShow = true"
					>
						<i class="el-icon-full-screen"></i> 预览
					</el-button>
				</div>

				<div>
					<el-button type="primary" @click="send" class="send" size="small">发送</el-button>
				</div>
			</div>
		</div>

		<el-dialog title="多模态上传" :visible.sync="imgInputVisible" width="500px">
			<div class="center">
				<el-upload
					name="img"
					list-type="picture"
					:limit="3"
					:on-success="uploadSuccess"
					:before-remove="beforeRemove"
					:on-exceed="handleExceed"
					drag
					action="https://ai-v2.deepcity.cn/user/api.userinfo/uploadImg"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过5mb</div>
				</el-upload>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="imgInputVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="预览" :visible.sync="isPrviewShow" :close-on-click-modal="false" width="800px">
			<el-input type="textarea" :rows="20" placeholder="请输入内容" v-model="textarea"></el-input>
		</el-dialog>
		<el-dialog
			:title="addRoleFrom.isAdd ? '新增协助角色' : '修改协助角色'"
			:visible.sync="isAddRoleShow"
			:close-on-click-modal="false"
			width="1000px"
		>
			<el-form ref="addForm" :model="addRoleFrom" class="demo-form-inline" :rules="rules" label-width="120px">
				<el-form-item label="新增方式" prop="roleAddType">
					<el-radio v-model="addRoleFrom.roleAddType" label="1" v-if="addRoleFrom.isAdd">AI员工导入</el-radio>
					<el-radio v-model="addRoleFrom.roleAddType" label="2">自定义角色</el-radio>
				</el-form-item>
				<el-form-item label="选择角色" prop="copywritingCategoryId" v-if="addRoleFrom.roleAddType === '1'">
					<el-select v-model="addRoleFrom.copywritingCategoryId" placeholder="请选择">
						<el-option :label="item.title" :value="item.id" v-for="item in classList" :key="item.id" />
					</el-select>
				</el-form-item>

				<el-form-item label="自定义角色名" prop="chatgtpContentTitle" v-if="addRoleFrom.roleAddType === '2'">
					<el-input v-model="addRoleFrom.chatgtpContentTitle" />
				</el-form-item>
				<el-form-item label="自定义提示语" prop="chatgtpContent" v-if="addRoleFrom.roleAddType === '2'">
					<!-- <el-input type="textarea" :rows="2" v-model="createReqFrom.rule" placeholder="提示语" /> -->
					<v-md-editor v-model="addRoleFrom.chatgtpContent" height="400px"></v-md-editor>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit('addForm')">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import ChatBox from '@/views/app/layout-chat/chat-box.vue';
import {
	clearLunci,
	genImage,
	getAbleModels,
	getRobotRoles,
	saveChatDialog,
	helpRoleList,
	addHelpRole,
	editHelpRole,
	deleteHelpRole,
} from '@/apis/chat';

import { getClassListApi, getClassContentListApi } from '@/apis/tools';

export default {
	name: 'layout-chat-main',
	components: { ChatBox },

	async created() {
		this.models = await getAbleModels({ is_all_pc: 1 });
		this.baseRoles = await getRobotRoles();
		this.models.push({ name: '通用AI模型', value: '' });
		this.baseRoles.push({ name: '通用对话角色', value: '' });
		this.getHelpRoleList();
	},

	props: {
		_chatDialogs: Array,
		_chatingId: String,
		_chatObj: Object,
	},

	data() {
		return {
			models: [],
			baseRoles: [],
			roles: [],
			selectModelKey: '',
			selectModel: { value: '', name: '通用AI模型', is_able_img: false },
			selectRoleKey: '',
			selectRole: { id: '', name: '通用对话角色' },
			textarea: '',
			msg: [],
			isNewGlobalMsg: false,

			// 图片
			msgImgs: [],
			imgInputVisible: false,
			isPrviewShow: false,
			isAddRoleShow: false,
			addRoleFrom: {
				isAdd: true,
				chatLunciGuid: '',
				roleAddType: '1',
				copywritingCategoryId: '',
				chatgtpContent: '',
				chatgtpContentTitle: '',
			},
			classList: [],
			helpList: [],
			rules: {
				copywritingCategoryId: [
					{
						required: true,
						message: '请选择角色',
						trigger: 'change',
					},
				],
				chatgtpContent: [
					{
						required: true,
						message: '请输入角色名',
						trigger: 'blur',
					},
				],
				chatgtpContentTitle: [
					{
						required: true,
						message: '请输入角色规则',
						trigger: 'blur',
					},
				],
			},
			isHelp: false,
		};
	},

	computed: {
		msgImgsCount() {
			return this.msgImgs.length;
		},

		// 支持图片
		isSupportImg() {
			return this.selectModel.is_able_img;
		},
	},

	methods: {
		//修改协助角色
		onEditRole(item) {
			let obj = null;
			this.helpList.forEach(element => {
				if (element.sysId === item.id) {
					obj = element;
				}
			});
			if (obj) {
				this.addRoleFrom.roleAddType = '2';
				this.addRoleFrom.chatLunciGuid = obj.helpLunciGuid;
				this.addRoleFrom.guid = obj.guid;
				this.addRoleFrom.chatgtpContent = obj.chatgtpContent;
				this.addRoleFrom.chatgtpContentTitle = obj.chatgtpContentTitle;
				this.addRoleFrom.isAdd = false;
				this.isAddRoleShow = true;
			}
		},
		//删除协作角色
		onDeleteRole(item) {
			this.$confirm('是否删除该角色', '删除角色', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
			})
				.then(async () => {
					let obj = this.helpList.find(element => {
						return element.sysId === item.id;
					});
					await deleteHelpRole({ guid: obj.guid });
					this.getHelpRoleList();
					this.$message({
						type: 'success',
						message: '删除成功',
					});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					});
				});
		},
		//新增协作角色
		onSubmit(formName) {
			this.$refs[formName].validate(async valid => {
				if (valid) {
					if (this.addRoleFrom.isAdd) {
						this.addRoleFrom.roleAddType = '1';
						this.addRoleFrom.chatLunciGuid = this._chatingId;
						let res = await addHelpRole(this.addRoleFrom);
						if (res.code === 0) {
							this.$nextTick(() => {
								this.$refs[formName].resetFields();
							});
							this.$message({ message: '新增成功', type: 'success' });
							this.isAddRoleShow = false;
							this.getHelpRoleList();
						} else {
							this.$message({ message: '新增失败', type: 'error' });
						}
					} else {
						let res = await editHelpRole(this.addRoleFrom);
						if (res.code === 0) {
							this.$nextTick(() => {
								this.$refs[formName].resetFields();
							});
							this.$message({ message: '修改成功', type: 'success' });
							this.isAddRoleShow = false;
							this.getHelpRoleList();
						} else {
							this.$message({ message: '修改失败', type: 'error' });
						}
					}
				} else {
					return false;
				}
			});
		},

		async getClassList() {
			let cateList = await getClassListApi({
				cateType: 'text',
				merchantGuid: 'e108201b02ae42e686bcc4c302cbbd11',
			});
			cateList.data.forEach(async item => {
				let res = await getClassContentListApi({
					cateId: item.id,
				});
				this.classList.push(...res.data);
			});
			// this.classList.push(...res.data);
			// this.sceneListId = res.data[0].id;
			// this.getClassContentList();
		},
		addRolePop() {
			this.addRoleFrom.isAdd = true;
			this.isAddRoleShow = true;
		},
		async getHelpRoleList() {
			let res = await helpRoleList({ chatLunciGuid: this._chatingId });
			let list = [];
			this.helpList = res.data;
			res.data.forEach(item => {
				let obj = {
					id: item.sysId,
					name: item.showRoleName,
					isHelp: true,
					isDelete: item.isDelete,
					isEdit: item.isEdit,
				};
				list.push(obj);
			});
			this.roles = this.baseRoles.concat(list);
		},
		uploadSuccess({ code, data }) {
			if (code == 0) {
				this.msgImgs.push(data);
			}
		},

		beforeRemove({ response }) {
			this.msgImgs = this.msgImgs.filter(item => item != response.data);
		},

		handleExceed() {
			alert('exceed ...');
		},

		choooseModel(value) {
			this.selectModelKey = value;
			this.selectModel = this.models.find(item => item.value == value);
		},

		choooseRole(role) {
			if (role.isHelp) {
				this.isHelp = true;
			}
			this.selectRoleKey = role.id;
			this.selectRole = this.roles.find(item => item.id == role.id);
		},

		cleanDialogs() {
			// this._chatDialogs = []
			this.$emit('clearChatDialogs');
			clearLunci(this._chatingId);
		},

		async send() {
			if (!this.textarea) return;

			let content = this.textarea;
			this.textarea = '';

			let lastMsgId = '';
			let len = this._chatDialogs.length;
			if (len > 0) {
				lastMsgId = this._chatDialogs[len - 1].msgId;
			}

			let saveMsgId = await saveChatDialog({
				content,
				lastMsgId,
				chatLunciGuid: this._chatingId,
				imgs: this.msgImgs,
				model: this.selectModelKey,
				isHelp: '1',
			});

			this._chatDialogs.push({ msgId: 'new', chatRole: 'user', chatContent: content, imgUrls: this.msgImgs });
			this._chatDialogs.push({ msgId: 'new', chatRole: 'assistant', chatContent: '', starting: true });

			this.msgImgs = [];

			this.toBottom();

			await this.waitChat(saveMsgId);

			return false;
		},

		// 滚动到最底部
		toBottom() {
			this.$nextTick(() => {
				let scrollDom = document.getElementById('chatbox');
				scrollDom.scrollTop = scrollDom.scrollHeight;
			});
		},

		async waitChat(lastMsgId) {
			let dialog = this._chatDialogs[this._chatDialogs.length - 1];
			let self = this;
			let params = `roleId=${this.selectRoleKey}&model=${this.selectModelKey}&msgId=${lastMsgId}&roleType=${
				this.isHelp ? '2' : '1'
			}`;
			let link = `https://ai-v2.deepcity.cn/square/api.chat/sendOpen?${params}`;

			console.log('selectModelKey : ', link);

			const source = new EventSource(link);
			source.onmessage = async function ({ data }) {
				data && (dialog.starting = false);

				if (data == '[DONE]') {
					dialog.msgId = await saveChatDialog({
						role: 'assistant',
						content: dialog.chatContent,
						lastMsgId,
						chatLunciGuid: self._chatingId,
						model: self.selectModelKey,
					});
					source.close();
				} else {
					dialog.chatContent += data.replace(/\\n/g, '\n');
				}
			};

			source.onopen = function (event) {
				console.log('Connection was opened');
				// dialog.starting = false
			};

			source.onerror = async function (event) {
				// console.log('event : ', event)
				// dialog.chatContent += '[ ERROR ]'
				source.close();
			};
		},

		// 请求图片 , imgUrls
		async waitingImage() {
			if (!this.textarea) return;

			let content = this.textarea;
			this.textarea = '';

			let lastMsgId = '';
			let len = this._chatDialogs.length;

			if (len > 0) {
				lastMsgId = this._chatDialogs[len - 1].msgId;
			}

			await saveChatDialog({
				content,
				lastMsgId,
				chatLunciGuid: this._chatingId,
				imgs: this.msgImgs,
				model: this.selectModelKey,
				isHelp: '1',
			});

			this._chatDialogs.push({ msgId: 'new', chatRole: 'user', chatContent: content, imgUrls: this.msgImgs });
			this._chatDialogs.push({ msgId: 'new', chatRole: 'assistant', chatContent: '', starting: true });

			this.msgImgs = [];

			this.toBottom();

			let url = await genImage(content);

			if (url) {
				let dialog = this._chatDialogs[this._chatDialogs.length - 1];

				dialog.starting = false;
				dialog.imgUrls = [url];

				dialog.msgId = await saveChatDialog({
					role: 'assistant',
					content: dialog.chatContent,
					lastMsgId,
					chatLunciGuid: this._chatingId,
					model: this.selectModelKey,
					imgs: [url],
				});
			}
		},
	},
	mounted() {
		this.getClassList();
	},
};
</script>

<style scoped lang="scss">
.role-drop-box {
	margin-bottom: 10px;
	.role-drop-title {
		width: 120px;
		margin-right: 10px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
::v-deep .el-dropdown-menu__item i {
	margin-right: 0 !important;
}
.invitation-btn {
	margin-left: 10px;
	display: flex;
	align-items: center;
}
.input-box {
	flex: 1;
	position: relative;
	.el-dropdown-link {
		position: absolute;
		right: 4px;
		bottom: 4px;
		z-index: 9;
	}
	margin-right: 20px;
}
::v-deep .el-dialog__body {
	padding: 10px 20px 20px 20px;
}
::v-deep .github-markdown-body p {
	margin-bottom: 0px;
}
.layout-chat-main {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	flex: 1;

	.chat-header {
		border-bottom: 1px solid var(--border-color);
		padding: 14px 20px;
		display: flex;
		align-items: center;
	}

	.chat-input {
		border-top: 1px solid var(--border-color);
		padding: 10px 20px;
	}

	.chat-box {
		flex: 1 1;
		overflow: auto;
		padding: 10px 0;
	}

	.send {
		height: 40px;
		width: 80px;
	}

	.opt-panel {
		margin-bottom: 10px;
		display: flex;

		.el-button {
			padding: 2px 11px;
			font-size: 12px;
			font-weight: normal;

			i {
				font-size: 12px;
			}
		}
	}
}
</style>
